import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import BG from '../images/awards-bg.jpg'

class AllNews extends React.Component {
	render() {
		const { data } = this.props
		const siteTitle = data.site.siteMetadata.title
		const sidelist = this.props.data.allSanityNews.edges
		const fnclnws = data.allSanityFinancialnews.edges
		console.log(fnclnws)
		// console.log(this.props)

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO
					title="All Financial News"
					keywords={[`blog`, `gatsby`, `javascript`, `react`]}
				/>
				<div
					id="sliderhead"
					className="blog-header2"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
				>
					<div className="container">
						<div className="text-det">
							<h5 className="related-title">Financial Planning</h5>
						</div>
					</div>
				</div>
				<div id="list-allblog" className="main-container">
					<div className="container">
						<div className="row">
							<div className="col-md-8 left-list">
								<div id="list-blog-all">
									{fnclnws.map(nws => (
										<div class="news-items" key={nws.node.id}>
											<div class="lead-sec">
												<div class="lead-image">
													<img
														src={
															nws &&
															nws.node &&
															nws.node.mainImage &&
															nws.node.mainImage.asset &&
															nws.node.mainImage.asset.fluid &&
															nws.node.mainImage.asset.fluid.src
														}
														alt="Banner"
														class="img-responsive"
													/>
												</div>
												{nws.node.tags.length === 0 ? null : (
													<h5 class="lead-tag">{nws.node.tags[0].title}</h5>
												)}
											</div>
											<div class="blog-det">
												<h3 class="blog-title">
													<Link to={nws.node.slug.current}>
														{nws.node.title}
													</Link>
												</h3>
												<div class="published">
													<p class="info">
														<i class="fa fa-bookmark-o" /> <i>By</i>{' '}
														<b>{nws.node.author.name}</b> |{' '}
														<i class="fa fa-clock-o" /> Created on{' '}
														<b>{nws.node.publishedAt}</b>
													</p>
												</div>
												<div class="short-desc">
													<p>{nws.node.excerpt}</p>
													<p class="read-more">
														<Link to={nws.node.slug.current}>
															Read More
															<i
																aria-hidden="true"
																class="fa fa-long-arrow-right"
															/>
														</Link>
													</p>
												</div>
											</div>
										</div>
									))}

									<nav>
										<ul className="pager">
											<li className="previous disabled  d-inline">
												<span>← Prev</span>
											</li>
											<li className="next float-right d-inline">
												<Link to="/latest-news">
													<span>Next →</span>
												</Link>
											</li>
										</ul>
									</nav>
								</div>
							</div>
							<div className="col-md-4 right-list pull-right">
								<div id="rightside-more-blog">
									<div className="more-ttle-head">
										<h3 className="ttle-post">More News</h3>
									</div>
									{sidelist.map(list => (
										<div className="moreblog" key={list.node.id}>
											<div className="row">
												<div className="col-md-5 left-more">
													<div className="lead-image">
														<img
															src={
																list &&
																list.node &&
																list.node.mainImage &&
																list.node.mainImage.asset &&
																list.node.mainImage.asset.fluid &&
																list.node.mainImage.asset.fluid.src
															}
															className="img-responsive"
															alt="Latest News"
														/>
													</div>
												</div>
												<div className="col-md-7 right-more">
													<h3 className="moreblog-title">
														<Link
															to={
																list &&
																list.node &&
																list.node.slug &&
																list.node.slug.current
															}
														>
															{list.node.title}
														</Link>
													</h3>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}
export default AllNews

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
		allSanityFinancialnews(
			filter: { status: { eq: "published" } }
			sort: { fields: publishedAt, order: DESC }
		) {
			edges {
				node {
					id
					title
					publishedAt(formatString: "MMMM DD, YYYY")
					author {
						id
						name
					}
					slug {
						current
					}
					tags {
						title
					}
					excerpt
					mainImage {
						asset {
							fluid {
								src
							}
						}
					}
				}
			}
		}
		allSanityNews(limit: 6) {
			edges {
				node {
					id
					title
					publishedAt(formatString: "MMMM DD, YYYY")
					author {
						id
						name
					}
					slug {
						current
					}
					excerpt
					mainImage {
						asset {
							fluid {
								src
							}
						}
					}
				}
			}
		}
	}
`
